import { computed, set } from 'vue'
import { get, map, sortBy, first, isEmpty } from 'lodash'
import { toFixed } from '@/utils/number'
export const useLottery = (data) => {
  const displayData = computed(() => {
    return map(data.value, (item) => {
      const { LotteryAward } = item
      const id = get(LotteryAward, 'id')
      const Image = get(LotteryAward, 'Image')
      const award = get(LotteryAward, 'award')
      const showAvailableStock = get(LotteryAward, 'showAvailableStock')
      const unlimitedStock = get(LotteryAward, 'unlimitedStock')
      const description = get(LotteryAward, 'description')
      const notice = get(LotteryAward, 'notice')
      // const awardType = get(LotteryAward, 'award.type')
      const awardCouponId = get(LotteryAward, 'award.couponId')
      const name = get(LotteryAward, 'name')
      const availableStock = get(LotteryAward, 'availableStock')
      const usedStock = get(LotteryAward, 'usedStock')
      const lastStock = availableStock - usedStock
      const errors = get(item, 'errors')
      const abnormals = get(item, 'abnormals')
      let type = 'normal'
      if (!isEmpty(errors)) {
        const sortedErrors = sortBy(errors, 'priority')
        type = get(first(sortedErrors), 'type')
      } else if (!isEmpty(abnormals)) {
        const sortedAbnormals = sortBy(abnormals, 'priority')
        type = get(first(sortedAbnormals), 'type')
      }
      const newProbability = get(LotteryAward, 'newProbability')
      const predictProbability = get(item, 'predictProbability')
      const realProbability = get(item, 'realProbability')
      const probabilityPercent = computed({
        get: () => toFixed(predictProbability * 100, 2),
        set: (value) => {
          set(item, 'predictProbability', value / 100)
          if (type === 'normal') set(item, 'realProbability', value / 100)
        },
      })

      return {
        id,
        Image,
        award,
        showAvailableStock,
        unlimitedStock,
        description,
        notice,
        name,
        type,
        availableStock,
        usedStock,
        lastStock,
        newProbability,
        realProbability,
        probabilityPercent,
        predictProbability,
        awardCouponId,
      }
    })
  })

  return {
    displayData,
  }
}
