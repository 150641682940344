<template>
  <BaseDialog
    v-bind="$attrs"
    title="編輯預期中獎率"
    width="690px"
    btn1="儲存"
    btn2="返回"
    :btn1-disabled="isError"
    @confirm="save"
    @close="$emit('close')"
  >
    <el-form ref="formRef">
      <el-form-item label="中獎率計算方式：" prop="rateType">
        <el-radio-group v-model="formData.rateType" @change="rateTypeChange">
          <el-tooltip placement="bottom">
            <div slot="content" style="width: 252px">
              各獎項的實際中獎率 = 獎項數量 / 獎項總數
              若獎項「已送完、失效、無庫存」，該獎項的中獎率按比例分配給其他獎項
            </div>
            <el-radio :label="false">依獎項數量自動分配</el-radio>
          </el-tooltip>
          <el-tooltip placement="bottom">
            <div slot="content" style="width: 204px">
              各獎項的實際中獎率 = 預期中獎率
            </div>
            <el-radio :label="true">手動編輯預期中獎率</el-radio>
          </el-tooltip>
        </el-radio-group>
      </el-form-item>
      <el-table :data="displayDataWithSummary" class="reward-table" :row-class-name="tableRowClassName">
        <el-table-column label="獎項名稱" prop="name" align="center" width="120px" />
        <el-table-column label="獎項狀態" prop="type" align="center">
          <template slot-scope="scope">
            <Tag v-if="scope.row.type" :type="awardTagType(scope.row.type)">
              {{ awardTrans(scope.row.type) }}
            </Tag>
          </template>
        </el-table-column>
        <el-table-column label="預期中獎率(%)" prop="newProbability" align="center">
          <template slot-scope="scope">
            <el-form-item v-if="scope.row.name !== ''">
              <el-input v-if="formData.rateType" v-model="scope.row.probabilityPercent" type="number" @input="updateProbability" />
              <el-tooltip v-else placement="bottom">
                <div slot="content" style="width: 120px">
                  欲編輯預期中獎率，請將中獎率計算方式改為「手動編輯預期中獎率」
                </div>
                <el-input v-model="scope.row.probabilityPercent" disabled />
              </el-tooltip>
            </el-form-item>
            <p v-else class="text-sub flex flex-col">
              預期中獎率總和
              <span v-if="edited" class="text-sub font-bold" :class="classType(sumPredictProbability)">{{ rounded(sumPredictProbability * 100) }}</span>
              <span v-else class="text-sub font-bold" :class="classType(newProbabilitySummary)">{{ rounded(scope.row.newProbability * 100) }}</span>
              <span v-if="warningOfZero" class="error">不得為 0</span>
              <span v-if="warningOfHundred" class="error">不得超過 100</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="實際中獎率" prop="realProbability" align="center">
          <template slot-scope="scope">
            <p v-if="scope.row.name === ''" class="text-sub flex flex-col">活動中獎率<span v-if="!formData.rateType">(扣除再接再厲)</span><span class="text-sub text-primary-100 font-bold"> {{ rounded(scope.row.realProbability * 100) }}%</span></p>
            <p v-else class="text-primary-100">{{ rounded(scope.row.realProbability * 100) }}%</p>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div class="mb-[40px]">
      <p class="notice">情境舉例：若活動中獎率為 70%，會員有 30% 機率抽到「再接再厲」</p>
      <p class="notice">特殊狀況：若獎項顯示特定狀態 (例如：已送完、<span class="text-danger">獎項失效</span>、<span class="text-danger">無庫存</span>)，該獎項的實際中獎率為 0%</p>
    </div>
    <WarningDialog
      v-if="modal.saveWarning"
      title="提醒"
      btnType="danger"
      @confirm="onConfirm"
      @close="modalClose('saveWarning')"
    >
      <template slot="body">
        <div>
          <p>儲存後，將變更抽獎活動的獎項機率，請確認目前設定的數值。</p>
        </div>
      </template>
    </WarningDialog>
    <WarningDialog
      v-if="modal.autoWarning"
      title="提醒"
      @close="formData.rateType = true;modalClose('autoWarning')"
      @confirm="modalClose('autoWarning');$emit('rateTypeChange')"
    >
      <template slot="body">
        <div>
          <p>變更為「依獎項數量自動分配」後，將移除手動輸入的預期中獎率。</p>
        </div>
      </template>
    </WarningDialog>
    <WarningDialog
      v-if="modal.customWarning"
      title="提醒"
      @close="formData.rateType = false;modalClose('customWarning')"
      @confirm="modalClose('customWarning');deleteMiss()"
    >
      <template slot="body">
        <div>
          <p>變更為「手動編輯預期中獎率」後，將隱藏獎項名稱為「再接再厲」的獎項。</p>
        </div>
      </template>
    </WarningDialog>
  </BaseDialog>
</template>

<script>
import { defineComponent, ref, reactive, computed, toRef, set, onMounted, watch } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { get, reduce, map, filter, find, isEmpty, sortBy, first, cloneDeep } from 'lodash'
import { useShop } from '@/use/shop'
import { BulkUpdateProbability } from '@/api/lottery/reward'
import { useRoute } from 'vue-router/composables'
import { awardTypeConfig } from '@/config/lottery'
import { useLottery } from '@/use/useLottery'
import { toFixed } from '@/utils/number'

export default defineComponent({
  name: 'GameRateDialog',
  components: { BaseDialog, WarningDialog },
  props: {
    data: { type: Array, default: () => [] },
    enableCustomProbability: { type: Boolean, default: false },
  },
  emits: ['close', 'refresh', 'deleteMiss', 'rateTypeChange'],
  setup (props, { emit }) {
    const { shopId } = useShop()
    const modal = reactive({
      saveWarning: false,
      autoWarning: false,
      customWarning: false,
    })
    const newData = ref([])
    const sumPredictProbability = ref(null)
    const edited = ref(false)
    const isError = computed(() => {
      if (formData.rateType) {
        return sumPredictProbability.value === 0 || sumPredictProbability.value > 1
      } else {
        return newProbabilitySummary.value === 0 || newProbabilitySummary.value > 1
      }
    })
    const warningOfZero = computed(() => {
      if (formData.rateType) return sumPredictProbability.value === 0
      else return newProbabilitySummary.value === 0
    })
    const warningOfHundred = computed(() => {
      if (formData.rateType) return sumPredictProbability.value > 1
      else return newProbabilitySummary.value > 1
    })
    // const { displayData } = useLottery(toRef(props, 'data'))
    const displayData = computed(() => {
      return map(newData.value, item => {
        const id = get(item.LotteryAward, 'id')
        const name = get(item.LotteryAward, 'name')
        const availableStock = get(item.LotteryAward, 'availableStock')
        const errors = get(item, 'errors')
        const abnormals = get(item, 'abnormals')
        let type = 'normal'
        if (!isEmpty(errors)) {
          const sortedErrors = sortBy(errors, 'priority')
          type = get(first(sortedErrors), 'type')
        } else if (!isEmpty(abnormals)) {
          const sortedAbnormals = sortBy(abnormals, 'priority')
          type = get(first(sortedAbnormals), 'type')
        }
        const newProbability = get(item.LotteryAward, 'newProbability')
        const predictProbability = get(item, 'predictProbability')
        const realProbability = get(item, 'realProbability')
        const probabilityPercent = computed({
          get: () => toFixed(predictProbability * 100, 2),
          set: (value) => {
            set(item, 'predictProbability', value / 100)
            if (type === 'normal') set(item, 'realProbability', value / 100)
          },
        })
        const awardType = get(item, 'type')
        return {
          id,
          name,
          type,
          newProbability,
          realProbability,
          predictProbability,
          probabilityPercent: probabilityPercent.value,
          availableStock,
          awardType,
        }
      })
    })

    const summary = computed(() => {
      const data = displayData.value
      return {
        name: '',
        type: '',
        newProbability: newProbabilitySummary.value,
        realProbability: reduce(data, (total, item) => {
          if (get(item, 'awardType') === 'miss') {
            return total
          }
          return total + item.realProbability
        }, 0),
      }
    })
    const newProbabilitySummary = computed(() => {
      // return reduce(displayData.value, (total, item) => total + item.predictProbability, 0)
      return reduce(displayData.value, (total, item) => total + Number(item.probabilityPercent / 100), 0)
    })
    const displayDataWithSummary = computed(() => {
      return [...displayData.value, summary.value]
    })
    const submitData = computed(() => {
      return map(displayData.value, item => {
        return {
          LotteryAwardId: item.id,
          newProbability: formData.rateType ? item.probabilityPercent / 100 : undefined,
          availableStock: formData.rateType ? undefined : item.availableStock,
        }
      })
    })
    const { initFormData, formData, formRef } = useBaseForm()

    initFormData({
      rateType: props.enableCustomProbability,
    })
    const hasMiss = computed(() => {
      return displayData.value.findIndex(item => get(item, 'awardType') === 'miss') > -1
    })
    const rateTypeChange = (val) => {
      if (val && hasMiss.value) modal.customWarning = true
      else if (!val) modal.autoWarning = true
    }
    const classType = (val) => {
      if (val > 1 || val === 0) return 'text-danger'
    }
    const tableRowClassName = ({ row }) => {
      if (!row.name) return 'summary-row'
    }
    const rounded = (value) => {
      return toFixed(value, 2)
    }
    const route = useRoute()
    const onConfirm = async () => {
      await BulkUpdateProbability({
        shopId: shopId.value,
        lotteryId: route.params.lotteryId,
        data: {
          enableCustomProbability: formData.rateType,
          overwriteProbabilityList: submitData.value,
        },
      })
      emit('close')
      emit('refresh')
    }
    const awardTagType = (val) => {
      return get(awardTypeConfig[val], 'tagType')
    }
    const awardTrans = (val) => {
      return get(awardTypeConfig[val], 'label')
    }
    const modalClose = (type) => {
      modal[type] = false
    }
    const save = () => {
      if (formData.rateType) modal.saveWarning = true
      else onConfirm()
    }
    const deleteMiss = () => {
      emit('deleteMiss')
    }

    const updateProbability = () => {
      sumPredictProbability.value = reduce(displayData.value, (total, item) => total + Number(item.probabilityPercent / 100), 0)
      edited.value = true
    }
    onMounted(() => {
      newData.value = cloneDeep(get(props, 'data'))
    })
    watch(() => props.data, (val) => {
      newData.value = cloneDeep(val)
    })
    return {
      displayData,
      displayDataWithSummary,
      formData,
      formRef,
      onConfirm,
      tableRowClassName,
      rounded,
      modal,
      awardTagType,
      awardTrans,
      classType,
      newProbabilitySummary,
      rateTypeChange,
      modalClose,
      save,
      deleteMiss,
      updateProbability,
      sumPredictProbability,
      edited,
      isError,
      warningOfZero,
      warningOfHundred,
    }
  },
})
</script>

<style lang="postcss" scoped>
.reward-table {
  @apply mb-[20px]
}
::v-deep .reward-table .el-form-item {
  @apply mb-0;
}
::v-deep .el-form-item__content {
  @apply leading-normal;
}
::v-deep .el-input {
  @apply w-[80px];
}
::v-deep .el-input__inner {
  @apply text-center;
}
::v-deep .summary-row {
  @apply bg-[#f5f7fa];
}
.notice {
  @apply text-sm text-gray-80;
}
.error {
  @apply text-danger text-sm;
}
</style>
