<template>
  <div class="reward-manage-table">
    <el-table :data="displayData">
      <EmptyBlock slot="empty" />
      <el-table-column label="獎項名稱" prop="name" align="center" />
      <el-table-column label="獎項狀態" prop="type" align="center">
        <template slot-scope="scope">
          <Tag :type="awardTagType(scope.row.type)">
            {{ awardTrans(scope.row.type) }}
          </Tag>
        </template>
      </el-table-column>
      <el-table-column label="獎項數量" prop="availableStock" align="center" />
      <el-table-column label="送出數量" prop="usedStock" align="center" />
      <el-table-column label="剩餘數量" prop="lastStock" align="center" />
      <el-table-column label="預期中獎率(%)" prop="predictProbability" align="center">
        <template slot-scope="scope">
          {{ rounded(scope.row.predictProbability * 100) }}
        </template>
      </el-table-column>
      <el-table-column label="實際中獎率" prop="realProbability" align="center">
        <template slot-scope="scope">
          <p v-if="scope.row.type.includes(['sentOut'])" class="text-gray-50 text-sm">獎項已全數送出，不計算中獎率</p>
          <p v-else-if="['expiration','notExist'].includes(scope.row.type)" class="text-gray-50 text-sm">獎項失效，不計算中獎率</p>
          <p v-else-if="scope.row.type.includes(['noStock'])" class="text-gray-50 text-sm">獎項無庫存，不計算中獎率</p>
          <p v-else-if="scope.row.lastStock <= 0" class="text-gray-50 text-sm">獎項數量不足，不計算中獎率</p>
          <p v-else class="text-primary-100">{{ rounded(scope.row.realProbability * 100) }}%</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="操作"
        fixed="right"
        width="110"
        align="center"
      >
        <template slot-scope="scope">
          <TableEditBtnGroup
            @edit="onEditDialog(scope.row)"
            @delete="onDeleteDialog(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <DeleteDialog
      v-if="deleteDialog"
      @close="deleteDialog = false"
      @delete="onDelete()"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, toRef } from 'vue'
import { awardTypeConfig } from '@/config/lottery'
import { map, get, find } from 'lodash'
import { useLottery } from '@/use/useLottery'
import { toFixed } from '@/utils/number'
export default defineComponent({
  name: 'RewardManageTable',
  components: {
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  setup (props, { emit }) {
    const deleteDialog = ref(false)
    const selectRow = ref(null)
    const onEditDialog = (row) => {
      selectRow.value = row
      emit('edit', selectRow.value)
    }
    const onDeleteDialog = (row) => {
      deleteDialog.value = true
      selectRow.value = row
    }
    const onDelete = () => {
      emit('delete', selectRow.value)
      deleteDialog.value = false
    }
    const awardTagType = (val) => {
      return get(awardTypeConfig[val], 'tagType')
    }
    const awardTrans = (val) => {
      return get(awardTypeConfig[val], 'label')
    }
    const { displayData } = useLottery(toRef(props, 'tableData'))

    const rounded = (value) => {
      return toFixed(value, 2)
    }
    return {
      displayData,
      rounded,
      deleteDialog,
      onDeleteDialog,
      onDelete,
      onEditDialog,
      awardTagType,
      awardTrans,
    }
  },

})
</script>

<style lang="scss" scoped>
</style>
